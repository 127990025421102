import React from "react"
import styled, { keyframes } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Three from "../components/Backgrounds/Three"

const Content = styled.div`
  width: 100%;
  height: 100%;
`

const fadeIn = keyframes`
0% {
  opacity: 0;
}

100% {
  opacity: 100%;
}
`
const LogoText = styled.svg`
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  opacity: 0;
  animation: ${fadeIn} 1s ease-out 1 forwards;
`

const Logo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;

  width: 90%;
  max-width: 600px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const MixcloudContainer = styled.div`
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
`
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Three />
    <Content>
      <Logo>
        <LogoText width="1054" height="141" viewBox="0 0 1054 141">
          <path
            d="M693.75 103.57H693.54L649.63 0.200012H604.56V4.67001L620.77 5.64001V133.4L604.56 134.37V138.84H642.78V134.37L626.88 133.4L625.3 10.21H625.51L681.11 140.78H685.01L735.02 14.77H735.23L735.02 133.4L718.81 134.37V138.84H778.2V134.37L761.98 133.4V5.64001L778.2 4.67001V0.200012H735.02L693.75 103.57Z"
            fill="white"
          />
          <path
            d="M814.39 4.67001L830.61 5.64001V133.4L814.39 134.37V138.84H893.78V134.37L857.57 133.4V5.64001L873.78 4.67001V0.200012H814.39V4.67001Z"
            fill="white"
          />
          <path
            d="M1037.22 133.4L988.36 60.92L1024.27 5.64001L1038.06 4.67001V0.200012H1003.21V4.67001L1016.79 5.64001L984.47 55.09H984.26L951.19 5.64001L966.99 4.67001V0.200012H908.02V4.67001L920.97 5.64001L967.72 75.98L930.24 133.4L892.44 134.37V138.84H953.82V134.37L938.03 133.4L971.62 81.81H971.83L1005.95 133.4L987.63 134.37V138.84H1053.44V134.37L1037.22 133.4Z"
            fill="white"
          />
          <path
            d="M562.68 110.88C561.35 120.27 552.81 132.52 539.3 132.52L488.32 132.64L528.1 66.02C528.1 66.02 497.09 6.71999 495.99 5.38999C509.24 5.38999 513.73 5.28999 526.23 5.44999C529.81 5.49999 536.81 5.27999 540.3 6.08999C547.85 7.84999 560.97 12.18 561.88 26.41L563.62 46.37C563.62 46.37 566.2 46.37 567.84 46.37C567.84 33.59 568.01 12.87 568.01 0.149994C533.13 0.149994 504.52 0.149994 469.69 0.149994C469.2 2.97999 471.21 7.32999 471.21 7.32999C471.21 7.32999 503.74 71.08 504.49 72.03C503.64 72.8 470.59 128.7 470.59 128.7C470.59 128.7 466.01 134.56 466.83 138.83C502.78 138.83 532.63 138.83 568.58 138.83C568.58 125.94 568.58 109.15 568.58 96.34C566.85 96.34 565.12 96.34 563.31 96.34C563.07 98.08 562.89 109.37 562.68 110.88Z"
            fill="white"
          />
          <path
            d="M313.33 0.179993L312.28 47.79H318.07C320.1 37.95 321.6 31.45 322.54 28.31C323.49 25.17 324.4 22.69 325.28 20.88C326.16 19.07 327.23 17.19 328.49 15.24C329.75 13.29 331.12 11.81 332.6 10.77C334.07 9.72999 335.81 8.75999 337.81 7.85999C339.81 6.94999 342.04 6.33999 344.5 6.00999C346.96 5.68999 349.77 5.51999 352.92 5.51999C353.2 5.51999 353.78 5.53999 354.66 5.56999C355.54 5.59999 356.33 5.61999 357.03 5.61999L357.66 133.38L341.55 134.35V138.82H400.94V134.35L384.62 133.38V5.51999C385.32 5.51999 386.11 5.50999 386.99 5.46999C387.87 5.43999 388.45 5.41999 388.73 5.41999C392.31 5.41999 395.47 5.69999 398.21 6.23999C400.95 6.78999 403.35 7.50999 405.42 8.37999C407.49 9.25999 409.33 10.63 410.95 12.51C412.56 14.39 413.93 16.27 415.05 18.15C416.17 20.03 417.26 22.65 418.32 26.02C419.37 29.39 420.27 32.6 421 35.64C421.74 38.68 422.6 42.7 423.58 47.69H429.37L428.32 0.179993H313.33V0.179993Z"
            fill="white"
          />
          <path
            d="M276.21 129.11C273.75 127.1 271.4 124.25 269.15 120.56C266.34 116.03 263.52 110.83 260.67 104.97C257.83 99.11 255.63 94.75 254.09 91.9C251.77 87.76 249.33 84.37 246.77 81.75C244.21 79.12 240.77 76.97 236.45 75.29C232.13 73.61 227.02 72.73 221.13 72.67V72.48C234.89 70.67 245.81 66.67 253.88 60.48C261.95 54.3 265.99 45.66 265.99 34.59C265.99 28.44 264.64 23.11 261.94 18.61C259.24 14.11 255.41 10.53 250.46 7.87C245.51 5.22 239.89 3.27 233.61 2.04C227.33 0.81 220.29 0.19 212.5 0.19H156.38V4.66L172.6 5.63V133.39C172.6 133.39 163.19 134.32 156.96 134.32C150.73 134.32 115.62 133.53 115.62 133.53L65.49 0H60.88L12.4 133.52L0 134.47V138.82H32.08V134.47L18.24 133.53L29.05 102.76L32.04 94H31.98L52.89 35.18H53.3L73.04 91.09L77.44 103.56L88.14 133.52L74.3 134.46V138.81H156.37H215.76V134.34L199.54 133.37V73.73C201.15 73.67 203.51 73.63 206.59 73.63C208.42 73.63 210.15 74 211.8 74.75C213.45 75.49 215.06 76.74 216.64 78.49C218.22 80.24 219.59 81.91 220.75 83.49C221.91 85.08 223.28 87.41 224.86 90.49C226.44 93.57 227.65 96.08 228.49 98.02C234.25 111.11 238.32 119.7 240.7 123.81C243.09 127.93 245.81 131.15 248.86 133.48C251.91 135.81 254.98 137.29 258.07 137.9C261.16 138.52 265.37 138.82 270.71 138.82H288.19V133.48C286.01 133.48 284.08 133.25 282.4 132.8C280.73 132.34 278.66 131.11 276.21 129.11ZM203.86 68.28C201.75 68.28 200.32 68.25 199.54 68.18V6.3C203.54 5.78 206.91 5.52 209.65 5.52C213.79 5.52 217.48 5.97 220.7 6.88C223.93 7.79 226.88 9.31 229.55 11.45C232.22 13.59 234.27 16.61 235.71 20.5C237.15 24.39 237.87 29.13 237.87 34.71C237.87 45.87 235.32 54.25 230.23 59.87C225.15 65.48 216.36 68.28 203.86 68.28Z"
            fill="white"
          />
        </LogoText>
        <MixcloudContainer>
          <iframe
            title="Mixcloud"
            width="100%"
            height="150"
            src="https://www.mixcloud.com/widget/iframe/?autoplay=1&feed=%2Fartemix_dj%2F"
            frameborder="0"
            allow="autoplay"
          ></iframe>
        </MixcloudContainer>
      </Logo>
    </Content>
  </Layout>
)

export default IndexPage
