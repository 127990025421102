import React, { Component } from "react"
import styled, { withTheme } from "styled-components"
import * as THREE from "three"

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js"
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js"
import { FilmShader } from "three/examples/jsm/shaders/FilmShader.js"

const RenderedScene = styled.div`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    left: 0%;
    width: 100%;
  }
`

const mouse = new THREE.Vector2()

var mouseX = 0
var mouseY = 0
var currentPositionX = 0
var currentPositionY = 1

class Three extends Component {
  state = {
    left: 0,
    top: 0,
  }
  componentDidMount() {
    this.windowHalf = new THREE.Vector2(
      window.innerWidth / 2,
      window.innerHeight / 2
    )

    this.sceneSetup()
    this.addCustomSceneObjects()
    this.startAnimationLoop()
    window.addEventListener("resize", this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize)
    window.cancelAnimationFrame(this.requestID)
  }

  sceneSetup = () => {
    // get container dimensions and use them for scene sizing

    this.scene = new THREE.Scene()
    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      0.1,
      500
    )
    this.camera.position.z = 11

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.setSize(window.innerWidth, window.innerHeight)

    this.mount.appendChild(this.renderer.domElement) // mount using React ref
    document.addEventListener("mousemove", e => {
      mouseX = 1 - (e.pageY / window.innerWidth) * 2
      mouseY = 1 - (e.pageX / window.innerHeight) * 2
    })

    this.fxaaPass = new ShaderPass(FXAAShader)
    this.filmPass = new ShaderPass(FilmShader)

    this.bloomPass = new UnrealBloomPass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      1.5,
      0.4,
      0.85
    )
    this.bloomPass.exposure = 1
    this.bloomPass.threshold = 0
    this.bloomPass.strength = 1.3
    this.bloomPass.radius = 0.7

    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(new RenderPass(this.scene, this.camera))

    this.composer.addPass(this.fxaaPass)
    this.composer.addPass(this.filmPass)

    this.composer.addPass(this.bloomPass)
  }

  addCustomSceneObjects = () => {
    const geometryFrontShpere = new THREE.SphereGeometry(1, 50, 50)
    const geometryBackSphere = new THREE.SphereGeometry(1, 50, 50)

    const materialFront = new THREE.MeshStandardMaterial({
      opacity: 1,
    })
    const materialBack = new THREE.MeshStandardMaterial({
      transparent: true,
      opacity: 1,
      emissive: 0xffffff,
      emissiveIntensity: 0.7,
    })

    this.frontSphere = new THREE.Mesh(geometryFrontShpere, materialFront)
    this.frontSphere.position.x = 0

    this.backSphere = new THREE.Mesh(geometryBackSphere, materialBack)
    this.backSphere.position.z = -1

    this.scene.add(this.frontSphere)
    this.scene.add(this.backSphere)

    //ADD LIGHT

    this.light1 = new THREE.PointLight(0xffffff, 2, 10)
    this.light1.position.set(10, 2, 0)

    this.scene.add(this.light1)
  }

  startAnimationLoop = () => {
    if (currentPositionX < mouseX) {
      currentPositionX += (mouseX - currentPositionX) / 40
    } else {
      currentPositionX -= (currentPositionX - mouseX) / 40
    }

    if (currentPositionY < mouseY) {
      currentPositionY += (mouseY - currentPositionY) / 40
    } else {
      currentPositionY -= (currentPositionY - mouseY) / 40
    }

    this.frontSphere.position.y = -0.05 - currentPositionX / 30
    this.frontSphere.position.x = -0.15 + currentPositionY / 15
    this.light1.intensity = 0 - currentPositionY * 5

    this.renderer.render(this.scene, this.camera)
    this.composer.render()

    // The window.requestAnimationFrame() method tells the browser that you wish to perform
    // an animation and requests that the browser call a specified function
    // to update an animation before the next repaint
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop)
  }
  handleWindowResize = () => {
    const width = this.mount.innerWidth
    const height = this.mount.innerHeight

    this.windowHalf.set(width / 2, height / 2)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
    // this.renderer.setPixelRatio(
    //   this.window.devicePixelRatio ? this.window.setPixelRatio : 1
    // )

    // Note that after making changes to most of camera properties you have to call
    // .updateProjectionMatrix for the changes to take effect.
  }
  onMouseMove(event) {
    mouse.x = event.clientX - this.windowHalf.x
    mouse.y = event.clientY - this.windowHalf.x
  }

  render() {
    return (
      <div>
        <RenderedScene
          style={{ left: this.state.left, top: this.state.top }}
          className="cursor"
          ref={mount => {
            this.mount = mount
          }}
        />
      </div>
    )
  }
}
export default withTheme(Three)
